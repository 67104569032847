import React, { useEffect } from "react";
import { useAuth } from "../auth/useAuth";
import Loader from "../components/Loader";
import Login from "../ui/Login";

const LoginPage = () => {
  const { user, hasFetched } = useAuth();

  useEffect(() => {
    if (user) {
      window.location.replace("/spaces/");
    }
  }, [user]);

  return hasFetched ? <Login /> : <Loader>Loading...</Loader>;
};

export default LoginPage;
