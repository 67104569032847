import React from "react";
import ReactDOM from "react-dom";

import { useAuthFlow, AuthStep } from "../utils/useAuthFlow";
import { AuthDialog } from "./auth/AuthDialog";
import { CheckPlayerIdMessage } from "./auth/CheckPlayerIdMessage";
import {
  InputNew,
  SubTitle,
  Title,
  ContinueButton,
  DetailText,
  SubTitleSentence,
} from "./auth/AuthDialogComponents";
import { AuthOverlay } from "./auth/AuthOverlay";

/** Adapted from `<LogInButton>`. 2022/02/24 NOTE: Success step is different. */
export default function Login() {
  const {
    step,
    isSubmitting,
    resetStep,
    email,
    emailInput,
    onEmailChange,
    submitEmail,
    playerId,
    playerIdInput,
    playerIdCheckResultCode,
    onPlayerIdChange,
    checkPlayerId,
    submitPlayerId,
  } = useAuthFlow();

  switch (step) {
    case AuthStep.EnterEmail: {
      return (
        <>
          {ReactDOM.createPortal(
            <AuthOverlay translucent={false}>
              <AuthDialog closable={false}>
                <form onSubmit={submitEmail}>
                  <Title style={{ marginBottom: "0.5rem" }}>
                    Welcome to Sprout
                  </Title>
                  <SubTitle style={{ marginBottom: "2rem" }}>
                    We’re so excited that you’re here!
                  </SubTitle>
                  <InputNew
                    value={email}
                    aria-label="Email"
                    type="email"
                    name="email"
                    placeholder="Email address"
                    ref={emailInput}
                    required
                    autoFocus
                    onChange={onEmailChange}
                    disabled={isSubmitting}
                    style={{ marginBottom: "3rem", width: "100%" }}
                  />
                  <ContinueButton
                    color="primary"
                    style={{ marginBottom: "2rem" }}
                    disabled={isSubmitting || email.length === 0}
                  >
                    Continue
                  </ContinueButton>
                  <DetailText style={{ maxWidth: "15rem" }}>
                    By continuing, you’re agreeing to our Terms of Use and
                    Privacy Policy.
                  </DetailText>
                </form>
              </AuthDialog>
            </AuthOverlay>,
            document.body
          )}
        </>
      );
    }
    case AuthStep.CheckEmail: {
      return (
        <>
          {ReactDOM.createPortal(
            <AuthOverlay translucent={false}>
              <AuthDialog closable={false}>
                <Title style={{ marginBottom: "0.5rem" }}>
                  Check your email
                </Title>
                <SubTitle style={{ marginBottom: "2rem" }}>
                  We emailed a link to your address. Click the link in it to
                  continue.
                </SubTitle>
                <ContinueButton color="secondary" onClick={resetStep}>
                  Use a different email
                </ContinueButton>
              </AuthDialog>
            </AuthOverlay>,
            document.body
          )}
        </>
      );
    }
    case AuthStep.EnterPlayerId: {
      return (
        <>
          {ReactDOM.createPortal(
            <AuthOverlay translucent={false}>
              <AuthDialog closable={false}>
                <form onSubmit={submitPlayerId}>
                  <Title style={{ marginBottom: "0.5rem" }}>
                    Claim your Sprout ID
                  </Title>
                  <SubTitle style={{ marginBottom: "2rem" }}>
                    <SubTitleSentence>
                      Pick a unique Sprout ID that you’d like to use!
                    </SubTitleSentence>
                    <SubTitleSentence>
                      Your Sprout ID will be public. It’s different than
                      nickname, which is next to your cursor that others see in
                      space.
                    </SubTitleSentence>
                  </SubTitle>
                  <InputNew
                    value={playerId}
                    aria-label="Sprout ID"
                    type="text"
                    name="playerId"
                    placeholder="Sprout ID"
                    ref={playerIdInput}
                    required
                    autoFocus
                    onChange={onPlayerIdChange}
                    onBlur={checkPlayerId}
                    disabled={isSubmitting}
                    style={{ marginBottom: "0.5rem", width: "100%" }}
                  />
                  <CheckPlayerIdMessage
                    isChecking={isSubmitting}
                    resultCode={playerIdCheckResultCode}
                  />
                  <ContinueButton
                    color="primary"
                    disabled={isSubmitting || playerId.length === 0}
                    style={{ marginTop: "1.5rem" }}
                  >
                    Continue
                  </ContinueButton>
                </form>
              </AuthDialog>
            </AuthOverlay>,
            document.body
          )}
        </>
      );
    }
    case AuthStep.Done: {
      return (
        <>
          {ReactDOM.createPortal(
            <AuthOverlay translucent={false}>
              <AuthDialog closable={false}>
                <Title style={{ marginBottom: "0.5rem" }}>
                  Going online in 3, 2, 1...
                </Title>
                <SubTitle style={{ marginBottom: "2rem" }}>
                  Taking you to your spaces... Have fun! ☀️
                </SubTitle>
              </AuthDialog>
            </AuthOverlay>,
            document.body
          )}
        </>
      );
    }
    case AuthStep.Error:
    default: {
      return (
        <>
          {ReactDOM.createPortal(
            <AuthOverlay translucent={false}>
              <AuthDialog closable={false}>
                <Title style={{ marginBottom: "0.5rem" }}>
                  Oops, something went wrong...
                </Title>
                <SubTitle style={{ marginBottom: "2rem" }}>
                  We had trouble logging you in. Please try again.
                </SubTitle>
                <ContinueButton color="secondary" onClick={resetStep}>
                  Try again
                </ContinueButton>
              </AuthDialog>
            </AuthOverlay>,
            document.body
          )}
        </>
      );
    }
  }
}
